<!-- 巡检计划 -->
<template>
  <div id="inspection-plan">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>巡检计划</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="requestParam">
            <el-form-item label="计划名称">
              <el-input v-model="requestParam.planName" placeholder="请输入计划名称查询" clearable></el-input>
            </el-form-item>
            <el-form-item label="巡检周期">
              <el-select v-model="requestParam.cycle" placeholder="请选择巡检周期" :popper-append-to-body="false" clearable>
                <el-option label="日" :value="1"></el-option>
                <el-option label="周" :value="2"></el-option>
                <el-option label="月" :value="3"></el-option>
                <el-option label="季度" :value="4"></el-option>
              </el-select>
            </el-form-item >
            <el-form-item  label="运维执行人">
              <el-input v-model="requestParam.userName" placeholder="请输入执行人查询" clearable></el-input>
            </el-form-item>
            <el-form-item label="开始时间">
              <el-date-picker type="date" placeholder="选择开始时间" value-format="yyyy-MM-dd"
                v-model="requestParam.startTime" :append-to-body="false"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-date-picker type="date" placeholder="选择结束时间" value-format="yyyy-MM-dd"
                v-model="requestParam.endTime" :append-to-body="false"></el-date-picker>
            </el-form-item>
            <el-form-item label="执行状态">
              <el-select v-model="requestParam.state" placeholder="请选择执行状态" :popper-append-to-body="false" clearable>
                <el-option label="停止" :value="1"></el-option>
                <el-option label="启动" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="query-button">
            <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>
            <el-button :class="isStringInArray('btnNewPlan') ? '' : 'btnShowAuthority'"  type="primary" icon="el-icon-plus" @click="createDialog">新建计划</el-button>
          </div>
        </div>
        <el-table class="table" :data="tableData">
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{
            (requestParam.pageNum - 1) * requestParam.pageSize +
            scope.$index +
            1
          }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="planName" label="计划名称" :fluid="true">
          </el-table-column>
          <el-table-column align="center" prop="planNumber" label="计划编号">
          </el-table-column>
          <el-table-column align="center" prop="operationFormName" label="巡检单名称">
          </el-table-column>
          <el-table-column align="center" prop="operationFormNum" label="巡检单编号">
          </el-table-column>
          <el-table-column align="center" prop="companyName" label="运维单位">
          </el-table-column>
          <el-table-column align="center" prop="buyer" label="巡检周期">
            <template slot-scope="scope">
              {{ scope.row.cycle==1? '日':scope.row.cycle==2?'周':scope.row.cycle==3 ? '月':'季度' }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="principalName" label="运维负责人">
          </el-table-column>
          <el-table-column align="center" prop="userName" label="运维执行人">
          </el-table-column>
          <el-table-column align="center" prop="userName" label="计划状态">
            <template slot-scope="scope">
              {{ scope.row.state == 1 ? '停止':  scope.row.state ==2? '启动' :  scope.row.state =='null'? '未启动':'未启动' }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="startTime" label="开始时间" width="175">
          </el-table-column>
          <el-table-column align="center" prop="endTime" label="结束时间" width="175">
          </el-table-column>

          <el-table-column align="center" label="操作" prop="operate" width="250">
            <template slot-scope="scope">
              <div class="operate">
                <el-button type="text" @click="viewDialog(scope.row)">详情</el-button>
                <span :class="isStringInArray('btnStart') ? '' : 'btnShowAuthority'">
                  <span v-if="scope.row.state !=2">|</span>
                  <el-button v-if="scope.row.state !=2" type="text" @click="alterDialog(scope.row,2)">启动</el-button>
                </span>
                <span :class="isStringInArray('btnEdit') ? '' : 'btnShowAuthority'">
                  <span v-if="scope.row.state !=2">|</span>
                  <el-button v-if="scope.row.state !=2" type="text" @click="updateDialog(scope.row)">编辑</el-button>
                </span>
                <span :class="isStringInArray('btnStop') ? '' : 'btnShowAuthority'">
                  <span v-if="scope.row.state !=1" >|</span>
                  <el-button v-if="scope.row.state !=1" type="text" @click="alterDialog(scope.row,1)">停止</el-button>
                </span>
                <span :class="isStringInArray('btnDelete') ? '' : 'btnShowAuthority'">
                  <span>|</span>
                  <el-button type="text" @click="deleteDialog(scope.row)">删除</el-button>
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
            @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input size="mini" class="pagination-input" v-model.number="inputValue"
              @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="requestType === 0 ? '新建巡检计划' : ''" :visible.sync="dialogVisible" width="761px"
      @close="dialogClose('planForm')">
      <div class="dialog-content">
        <el-form class="dialog-form" :inline="true" ref="planForm"  :model="planData" :rules="rules"
          label-width="80px">
          <div class="plan-content planUpdateDialog">
            <el-form-item label="计划名称" prop="planName">
              <el-input v-model="planData.planName" placeholder="请输入计划名称"></el-input>
            </el-form-item>
            <el-form-item label="巡检单" prop="operationInspectionFormId">
              <el-select v-model="planData.operationInspectionFormId" :popper-append-to-body="false" clearable
                placeholder="请选择巡检单">
                <el-option v-for="item in inspectionForm" :key="item.id" :label="item.operationFormName"
                  :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="interval"></div>
          <div class="plan-button">
            <!-- <el-button type="primary" icon="el-icon-minus"></el-button>
              <el-button type="primary" icon="el-icon-plus"></el-button> -->
          </div>
          <div class="newItem" >
            <el-button class="" type="primary" size="small" @click="addPlanList">添加</el-button>
          </div>
          <div class="plan">
            <div class="plan-content" v-for="(item, index) in planData.planList" :key="index">
              <div class="deleteIcon" @click="deletePlanList(index)" v-if="planData.planList.length > 1"><i class="el-icon-circle-close"></i></div>
              <el-form-item label="场站"  :prop="'planList.' + index + '.stationId'" :rules="[{ required: true, message: '请输入场站', trigger: 'change' }]">
                <!-- <el-input v-model="planData.stationId"></el-input> -->
                <el-input class="operationStyle" placeholder="请选择变配电站" v-model="item.stationName" :readonly="true">
                  <template slot="append">
                    <span @click="selectStation(item, index)" class="appendSlot">选择配电站</span>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item label="运维单位" :prop="'planList.' + index + '.companyId'" :rules="[{ required: true, message: '请选择运维单位', trigger: 'change' }]">
                <!-- <el-input v-model="item.companyId" StaComList placeholder="请输入开始时间"></el-input> -->
                <el-select @change="(value) => selectCom(value, item, index)" v-model="item.companyId"
                  :popper-append-to-body="false" clearable placeholder="请选择运维单位">
                  <el-option v-for="Com in item.StaComList" :key="Com.companyId" :label="Com.companyName"
                    :value="Com.companyId"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="单位负责人" :prop="'planList.' + index + '.principalName'" >
              <el-input v-model="item.principalName" placeholder="请选择负责人" :readonly="true"></el-input>
            </el-form-item>
              <el-form-item label="运维负责人" :prop="'planList.' + index + '.principalId'" :rules="[{ required: true, message: '请选择运维负责人', trigger: 'change' }]">
                <el-select v-model="item.principalId" :popper-append-to-body="false" clearable placeholder="请选择运维负责人">
                  <el-option v-for="user in item.principalIdList" :key="user.userId" :label="user.userName"
                    :value="user.userId"></el-option>
                </el-select>
            </el-form-item>
              <el-form-item label="运维执行人" :prop="'planList.' + index + '.executeUserId'" :rules="[{ required: true, message: '请选择运维执行人', trigger: 'change' }]">
                <!-- <el-input v-model="item.executeUserId"></el-input> executeUserList -->
                <el-select v-model="item.executeUserId" :popper-append-to-body="false" clearable placeholder="请选择运维执行人">
                  <el-option v-for="user in item.executeUserList" :key="user.userId" :label="user.userName"
                    :value="user.userId"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="开始时间" :prop="'planList.' + index + '.startTime'" :rules="[{ required: true, message: '请选择开始时间', trigger: 'change' }]">
                <!-- <el-input v-model="item.startTime" placeholder="请输入开始时间"></el-input> -->
                <el-date-picker @change="()=>{addItemTime(index)}" class="custom-picker" :append-to-body="false" v-model="item.startTime"
                  value-format="yyyy-MM-dd " type="date" placeholder="请选择开始时间"
                  :picker-options="pickerOptions"></el-date-picker>
              </el-form-item>
              <el-form-item label="结束时间" :prop="'planList.' + index + '.endTime'" :rules="[{ required: true, message: '请选择结束时间', trigger: 'change' }]">
                <!-- <el-input v-model="item.startTime" placeholder="请输入开始时间"></el-input> -->
                <el-date-picker @change="()=>{addItemTime(index)}" class="custom-picker" :append-to-body="false" v-model="item.endTime"
                  value-format="yyyy-MM-dd " type="date" placeholder="请选择结束时间"
                  :picker-options="pickerOptions"></el-date-picker>
              </el-form-item>
              <el-form-item label="计划周期" :prop="'planList.' + index + '.cycle'" :rules="[{ required: true, message: '请选择计划周期', trigger: 'change' }]">
                <el-select v-model="item.cycle" :popper-append-to-body="false" clearable placeholder="请选择计划周期">
                  <el-option label="日" :value="1"></el-option>
                  <el-option label="周" :value="2"></el-option>
                  <el-option label="月" :value="3"></el-option>
                  <el-option label="季度" :value="4"></el-option>
                </el-select>
              </el-form-item>

              <!-- <div class="deleteItem" v-if="planData.planList.length > 1">
                <el-button class="" type="primary" size="small" @click="deletePlanList(index)">删除</el-button>
              </div> -->
            </div>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogConfirm('planForm')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title=" requestType==1?'修改巡检计划':'详情'" :visible.sync="updateDialogVisible" width="761px"
      @close="dialogClose('modifyPlanList')">
      <div class="dialog-content">
        <el-form class="dialog-form" ref="modifyPlanList" :inline="true" :model="modifyPlanList" :rules="rules"
          label-width="80px">
          <div class="plan-content planUpdateDialog">
            <el-form-item label="场站" prop="operationFormName">
            <el-input :disabled="requestType ==2" class="operationStyle" placeholder="请选择变配电站" v-model="modifyPlanList.stationName" :readonly="true">
              <template slot="append" >
                <el-button  :disabled="requestType !=1" @click="selectStation(modifyPlanList)" class="appendSlot">选择配电站</el-button>
              </template>
            </el-input>
          </el-form-item>
            <el-form-item label="计划名称" prop="planName">
              <el-input :disabled="requestType ==2" v-model="modifyPlanList.planName" placeholder="请输入计划名称"></el-input>
            </el-form-item>
            <el-form-item label="巡检单" prop="operationInspectionFormId">
              <el-select :disabled="requestType ==2" v-model="modifyPlanList.operationInspectionFormId" :popper-append-to-body="false" clearable
              placeholder="请选择巡检单">
            <el-option v-for="item in inspectionForm" :key="item.id" :label="item.operationFormName"
            :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="巡检单编号" prop="planName" v-if="requestType !=1">
            <el-input :disabled="requestType ==2" v-model="operationFormNum" placeholder="请输入计划名称"></el-input>
          </el-form-item>
            <el-form-item label="运维单位" prop="companyId">
              <!-- <el-input v-model="item.companyId" StaComList placeholder="请输入开始时间"></el-input> -->
              <el-select :disabled="requestType ==2" @change="selectUser" v-model="modifyPlanList.companyId"
                :popper-append-to-body="false" clearable placeholder="请选择运维单位">
                <el-option v-for="Com in modifyPlanList.StaComList" :key="Com.companyId" :label="Com.companyName"
                  :value="Com.companyId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="单位负责人"  >
              <el-input v-model="modifyPlanList.companyPrincipalName" placeholder="请选择单位负责人" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="运维负责人"  prop="principalId">
              <!-- <el-input v-model="modifyPlanList.principalName" placeholder="请选择运维负责人" :readonly="true"></el-input> -->
              <el-select :disabled="requestType ==2"  v-model="modifyPlanList.principalId" :popper-append-to-body="false" clearable placeholder="请选择执行人">
                <el-option v-for="user in modifyPlanList.principalIdList" :key="user.userId" :label="user.userName"
                  :value="user.userId"></el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="运维执行人" prop="executeUserId">
              <!-- <el-input v-model="item.executeUserId"></el-input> executeUserList -->
              <el-select :disabled="requestType ==2"  v-model="modifyPlanList.executeUserId" :popper-append-to-body="false" clearable placeholder="请选择执行人">
                <el-option v-for="user in modifyPlanList.executeUserList" :key="user.userId" :label="user.userName"
                  :value="user.userId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间" prop="startTime">
              <!-- <el-input v-model="item.startTime" placeholder="请输入开始时间"></el-input> -->
              <el-date-picker :disabled="requestType ==2" @change="modifyItemTime" class="custom-picker" :append-to-body="false" v-model="modifyPlanList.startTime"
                value-format="yyyy-MM-dd " type="date" placeholder="请选择开始时间"
                :picker-options="pickerOptions"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" prop="endTime">
              <!-- <el-input v-model="item.startTime" placeholder="请输入开始时间"></el-input> -->
              <el-date-picker :disabled="requestType ==2" @change="modifyItemTime" class="custom-picker" :append-to-body="false" v-model="modifyPlanList.endTime"
                value-format="yyyy-MM-dd " type="date" placeholder="请选择结束时间"
                :picker-options="pickerOptions"></el-date-picker>
            </el-form-item>
            <el-form-item label="计划周期" prop="cycle">
              <el-select :disabled="requestType ==2" v-model="modifyPlanList.cycle" :popper-append-to-body="false" clearable placeholder="请选择计划周期">
                <el-option label="日" :value="1"></el-option>
                <el-option label="周" :value="2"></el-option>
                <el-option label="月" :value="3"></el-option>
                <el-option label="季度" :value="4"></el-option>
              </el-select>
            </el-form-item>

          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer" v-if="requestType !=2">
        <el-button @click="updateDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogConfirmModify('modifyPlanList')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="删除计划" :visible.sync="deleteVisible" width="436px">
      <div class="dialog-text">确定要删除该计划吗？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteOperationPlan">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="alterType==2? '启动':'停止'" :visible.sync="alterVisible" width="436px">
      <div class="dialog-text" v-if="alterType==2">确定要启动该计划吗？</div>
      <div class="dialog-text" v-if="alterType==1">确定要停止该计划吗？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="alterVisible = false">取 消</el-button>
        <el-button type="primary" @click="alterOperationPlan">确 定</el-button>
      </div>
    </el-dialog>
    <stationDialog :status="dialogStatus" :stationId="stationId" @selectedStation="getStationNameDialog"
      @statusChange="changeDialog"></stationDialog>
  </div>
</template>

<script>
import { operationPlanApi } from "@/api/operationPlan.js";
import stationDialog from "./compoents/stationDialog.vue";
export default {
  name: "",
  components: { stationDialog },
  data() {
    return {
      alterType:'',
      alterVisible:false,
      dialogStatus: false,
      stationId: 0,
      stationName: '',
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        startTime: null,
        endTime: null,
        state: "",
        userName:'',
        planName:'',
        planType: 1,
      },
      operationFormNum:'',
      total: 0,
      inputValue: 1,
      requestType: 0,
      tableData: [],
      planData: {
        planName: '',//计划名称
        operationInspectionFormId: '',//巡检单id
        planList: [
          {
            stationId: '',//场站
            stationName: '',
            startTime: '',//开始时间 
            endTime: '',//结束时间
            cycle: '',//计划周期
            executeUserId: '',//执行人
            executeUserList: [],//执行人列表
            principalIdList: [],//执行人列表
            companyId: '',//运维单位
            StaComList: [],//运维单位列表
            principalId:'',//负责人
            principalName:'',//负责人姓名
            createBy:'',//创建人
          },
        ],
      },
      
      modifyPlanList: {
        planName: '',//计划名称
        operationInspectionFormId: '',//巡检单id
        stationId: '',//场站
        stationName: '',
        startTime: '',//开始时间 
        endTime: '',//结束时间
        cycle: '',//计划周期
        executeUserId: '',//执行人
        executeUserList: [],//执行人列表
        principalIdList: [],//执行人列表
        companyId: '',//运维单位
        StaComList: [],//运维单位列表
        principalId:'',//负责人
        principalName:'',//负责人姓名
        companyPrincipalName:'',
            // createBy:'',//创建人
      },
      viewVisible: false,
      deleteVisible: false,
      dialogVisible: false,
      updateDialogVisible: false,
      id: "",

      rules: {
        planName: [
          { required: true, message: "请输入计划名称", trigger: "blur" },
        ],
        operationInspectionFormId: [
          { required: true, message: "请选择巡检单", trigger: "change" },
        ],
        stationId: [
          { required: true, message: "请选择场站", trigger: "change" },
        ],
        startTime: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        endTime: [
          { required: true, message: "请选择结束时间", trigger: "change" },
        ],
        cycle: [
          { required: true, message: "请选择计划周期", trigger: "change" },
        ],
        executeUserId: [
          { required: true, message: "请选择运维执行人", trigger: "change" },
        ],
        principalId: [
          { required: true, message: "请选择运维负责人", trigger: "change" },
        ],
        companyId: [
          { required: true, message: "请选择运维单位", trigger: "change" },
        ],
      },
      defaultData: {
        purchaseTime: "",
        buyer: "",
        remark: "",
        accomplishFlage: "",
      },
      inspectionForm: [],
      planItem: '',
      index: '',
      pickerOptions: {
        disabledDate(time) {
          const today = new Date();
          const tomorrow = new Date(today);
          tomorrow.setDate(tomorrow.getDate()); // 获取明天的日期

          return time.getTime() < tomorrow.getTime();
        }
      },
      authorityListBtn:[],
    };
  },
  created() {
    this.$store.commit("increment", "计划管理");
    this.$store.commit("selectChild", "巡检计划");
    this.$store.commit("selectChildren", "");
  },
  mounted() {
    this.queryOperationPlan();
    this.inspectionList()
    this.authorityListBtn=sessionStorage.getItem('roleInfo')
  },
  methods: {
     //按钮权限
     isStringInArray(str) {
      if (this.authorityListBtn) {
        let list = this.authorityListBtn.includes(str);
        return list
      } else {
        return false
      }
    },
    //创建计划
    createOperationPlan() {
      // let obj = {
      //   stationId: '',//场站
      //   startTime: '',//开始时间 
      //   endTime: '',//结束时间 
      //   cycle: '',//计划周期
      //   executeUserId: '',//执行人
      //   companyId: '',//运维单位
      // }
      let submitForm = {
        planName: this.planData.planName,//计划名称
        operationInspectionFormId: this.planData.operationInspectionFormId,//巡检单id
        planType: 1,
        planList: [],
      }
      const value = JSON.parse(sessionStorage.getItem('user_info'));
      console.log(value);
      this.planData.planList.forEach(item => {
        let obj = {}
        obj.stationId = item.stationId
        obj.startTime = item.startTime
        obj.endTime = item.endTime
        obj.cycle = item.cycle
        obj.executeUserId = item.executeUserId
        obj.companyId = item.companyId
        obj.principalId = item.principalId
        obj.createBy=value.id
        console.log(obj);
        submitForm.planList.push(obj)
      })
      console.log(submitForm);
      operationPlanApi.createOperationPlan(submitForm).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.dialogVisible = false;
          this.queryOperationPlan();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    selectCom(value, item, index) {
      console.log(value, item, index);
      // obj= this.ComList.filter(item=>item.companyId===value)[0] StaComList executeUserList comUserList
      let obj = item.StaComList.filter(item => item.companyId === value)[0]
      console.log(obj);
      // this.planData.planList[index].principalId=
      this.planData.planList[index].executeUserList = obj.comUserList
      this.planData.planList[index].principalIdList = obj.fuzerenUserList
      // this.planData.planList[index].principalId= obj.contactNameId
      this.planData.planList[index].principalName= obj.contactName
      if(this.planData.planList[index].executeUserId){
        this.planData.planList[index].executeUserId=''
      }
      if(this.planData.planList[index].principalId){
        this.planData.planList[index].principalId=''
      }
      
      
    },
    selectUser(value){
      let obj = this.modifyPlanList.StaComList.filter(item => item.companyId === value)[0]
      console.log(obj);
      this.modifyPlanList.executeUserList = obj.comUserList
      this.modifyPlanList.principalIdList = obj.fuzerenUserList
      // this.modifyPlanList.principalId = obj.contactNameId
      this.modifyPlanList.companyPrincipalName = obj.contactName
      this.modifyPlanList.executeUserId=null
      this.modifyPlanList.principalId=null
    },
    //添加巡检项
    addPlanList() {
      this.planData.planList.push(
        {
            stationId: '',//场站
            stationName: '',
            startTime: '',//开始时间 
            endTime: '',//结束时间
            cycle: '',//计划周期
            executeUserId: '',//执行人
            executeUserList: [],//执行人列表
            principalIdList: [],//执行人列表
            companyId: '',//运维单位
            StaComList: [],//运维单位列表
            principalId:'',//负责人
            principalName:'',//负责人姓名
            createBy:'',//创建人
          }
      )
    },
    //删除巡检项
    deletePlanList(index) {
      // console.log(this.planData.planList);
      this.planData.planList.splice(index,1)
    },
    dialogClose(planForm) {
      this.planData = this.$options.data().planData
      this.$refs[planForm].resetFields();
      this.index=''
      this.operationFormNum=''
    },
    //新增
    dialogConfirm(planForm) {
      this.$refs[planForm].validate((valid) => {
      if (valid) {
      if (this.requestType === 0) {
      this.createOperationPlan();
      } else {
        this.updatePurchase();
      }
        } else {
          return false;
        }
      });
    },
    //修改
    dialogConfirmModify(modifyPlanList) {
      this.$refs[modifyPlanList].validate((valid) => {
      if (valid) {
        console.log('http://localhost:8080',this.modifyPlanList);
        this.updateOperationPlan();
        } else {
          return false;
        }
      });
    },
   //修改
   updateOperationPlan() {
      // this.planData.id = this.id;
    let obj ={
      planId:this.id,
      planName:this.modifyPlanList.planName,
      operationInspectionFormId:this.modifyPlanList.operationInspectionFormId,
      stationId:this.modifyPlanList.stationId,
      startTime:this.modifyPlanList.startTime,
      endTime:this.modifyPlanList.endTime,
      cycle:this.modifyPlanList.cycle,
      executeUserId:this.modifyPlanList.executeUserId,
      companyId:this.modifyPlanList.companyId,
      principalId:this.modifyPlanList.principalId,

    }
    console.log('obj~~~~~~~~~~~~~~~~~~~~~',obj);

      operationPlanApi.updateOperationPlan(obj).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.updateDialogVisible = false;
          this.queryOperationPlan();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },


    //获取巡检单
    inspectionList() {
      operationPlanApi.inspectionList().then(res => {
        this.inspectionForm = res.data
      })
    },

    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryEnergyStation();
    },
    createDialog() {
      this.requestType = 0;
      Object.assign(this.planData, this.defaultData);
      this.dialogVisible = true;
    },
    updateDialog(data) {
      this.requestType = 1;
      this.id = data.id;
      console.log(data);
      // this.planData.purchaseTime = data.purchaseTime;
      // this.planData.buyer = data.buyer;
      // this.planData.remark = data.remark;
      // this.planData.accomplishFlage = data.accomplishFlage;
     
      this.getDetails(data.id)

      this.updateDialogVisible = true;
    },
    //获取详情
    getDetails(id){
      operationPlanApi.getById(id).then(res=>{
        this.stationId=Number(res.data.stationId)
        this.modifyPlanList.planName=res.data.planName
        this.modifyPlanList.companyPrincipalName=res.data.companyPrincipalName
        this.modifyPlanList.operationInspectionFormId= Number(res.data.operationInspectionFormId)
        this.modifyPlanList.stationId=res.data.stationId
        this.modifyPlanList.stationName=res.data.stationName
        this.modifyPlanList.startTime=res.data.startTime
        this.modifyPlanList.endTime=res.data.endTime
        this.modifyPlanList.cycle=res.data.cycle
        this.modifyPlanList.executeUserId=res.data.executeUserId
        this.modifyPlanList.companyId=res.data.companyId
        this.modifyPlanList.principalId=res.data.principalId
        this.modifyPlanList.principalName=res.data.principalName
        this.operationFormNum=res.data.operationFormNum
        console.log( +this.stationId);
        operationPlanApi.getStaComList( Number(this.stationId)).then(response => {
          this.modifyPlanList.StaComList = response.data
          if(res.data.executeUserId && res.data.companyId){
            let obj = this.modifyPlanList.StaComList.filter(item => item.companyId === res.data.companyId)[0]
            console.log("1111111111111111111111", this.modifyPlanList.principalId);
            this.modifyPlanList.executeUserList = obj.comUserList
            this.modifyPlanList.principalIdList = obj.fuzerenUserList
          }
          })
      })

    },
       //详情
    viewDialog(data) {
      this.id = data.id;
      // this.viewFile();
      this.getDetails(data.id)
      this.requestType=2
      this.updateDialogVisible = true;
    },
    deleteDialog(data) {
      this.id = data.id;
      this.deleteType = 0;
      console.log(data);
      if(data.state==2){
        this.$message({
            message: '请先停止该任务后再删除',
            type: 'warning',
            duration: 3000,
            customClass: "messageText",
          });
      }else{
        this.deleteVisible = true;
      }
    },
     //场站选择选中时返回的
     getStationNameDialog(value) {
      console.log("222222",this.index);
      if(this.index || this.index==0 ){
        this.planData.planList[this.index].stationId = value.id
        this.planData.planList[this.index].stationName = value.stationName
        console.log(this.planData);
        operationPlanApi.getStaComList(value.id).then(res => {
          this.planData.planList[this.index].StaComList = res.data
          if(this.planData.planList[this.index].companyId){
            this.planData.planList[this.index].companyId = null
          }
          if(this.planData.planList[this.index].executeUserId){
            this.planData.planList[this.index].executeUserId = null
          }
          // this.planData.planList[this.index].executeUserId = null
        })
      }else{
        this.modifyPlanList.stationId=value.id
        this.modifyPlanList.stationName=value.stationName
        operationPlanApi.getStaComList(value.id).then(res => {
          this.modifyPlanList.StaComList = res.data
          this.modifyPlanList.companyId = null
          this.modifyPlanList.executeUserId = null
        })
      }
      this.dialogStatus = false;

    },
    //场站选择关闭时返回的
    changeDialog(value) {
      this.stationId = 0
      this.dialogStatus=false
    },
    selectStation(el, index) {
    console.log(el);
      this.planItem = el
      this.index = index
      this.stationId = Number( el.stationId ? el.stationId : 0)
      console.log(el,index);
      this.dialogStatus = true;
    },
    //新增时时间校验
    addItemTime(index){
      let startTime=new Date(this.planData.planList[index].startTime).getTime();
      let endTime=new Date(this.planData.planList[index].endTime).getTime();
      if(startTime > endTime){
        this.$message({
            message: '开始时间需要小于结束时间并且不能相等',
            type: 'warning',
            duration: 3000,
            customClass: "messageText",
          });
          this.planData.planList[index].endTime=null
      }
    },
    //新增时时间校验
    modifyItemTime(){
      let startTime=new Date(this.modifyPlanList.startTime).getTime();
      let endTime=new Date(this.modifyPlanList.endTime).getTime();
      if(startTime > endTime){
        this.$message({
            message: '开始时间需要小于结束时间并且不能相等',
            type: 'warning',
            duration: 3000,
            customClass: "messageText",
          });
          this.modifyPlanList.endTime=null
      }
    },

    //分页相关
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryOperationPlan();
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryOperationPlan();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryOperationPlan();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryOperationPlan();
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryOperationPlan();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryOperationPlan();
    },
    //查询
    queryOperationPlan() {
      operationPlanApi.queryOperationPlan(this.requestParam).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

  
    deleteOperationPlan() {
      operationPlanApi.deleteOperationPlan({ planId: this.id }).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.deleteVisible = false;
          this.queryOperationPlan();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    //启动/停止
    alterOperationPlan(){
      let obj={
        planId:this.id,
        state:this.alterType
      }
      operationPlanApi.alterOperationPlan(obj).then(res=>{
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.alterVisible = false;
          this.queryOperationPlan();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }

      })

    },
    //按钮启动或者停止
    alterDialog(el,type){
      this.alterType=type
      this.id=el.id
      this.alterVisible=true
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#inspection-plan {
  width: 100%;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}

.main {
  width: 100%;
  padding: 20px 26px 28px 25px;
  box-sizing: border-box;
}

/* dialog form */
.dialog-content {
  margin: 18px 50px 0 47px;
}

.dialog-form :deep().el-form-item__label {
  color: #c9d4f1;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
  padding: 0;
}

.dialog-form :deep() .el-input .el-input__inner {
  /* width: 565px; */
  width: 100%;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
  height: 40px !important;
}

.interval {
  width: 654px;
  height: 1px;
  background-color: #06224e;
  margin-bottom: 16px;
}

.plan {
  /* display: flex; */
  max-height: 700px;
  padding-top: 20px;
  overflow: auto;
}

::v-deep .plan::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .plan::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}

::v-deep .plan::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

.plan-content {
  /* width: 574px; */
  margin-right: 20px;
  padding: 16px 16px 10px 16px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border: 1px solid #06224e;
  position: relative;
}

.plan-content :deep() .el-input .el-input__inner {
  width: 520px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

.plan-content :deep() .operationStyle .el-input__inner {
  width: 410px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

.planUpdateDialog {
  border: 0px;
  padding: 0;
}

.planUpdateDialog :deep() .el-input .el-input__inner {
  /* width: 560px; */
  /* width: 100%; */
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

.planUpdateDialog :deep() .operationStyle .el-input__inner {
  /* min-width: 455px; */
  /* width: 100%; */
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

::v-deep .el-input-group__append {
  background: #143168;
  color: #fff;
  border: 0;
  cursor: pointer;
}

.plan-button button {
  height: 30px;
  width: 30px;
  padding: 0;
  background-color: #176ad2;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  color: #e8f4ff;
  border: 0;
  margin-left: 10px;
}

.newItem {
  margin: 0 0 20px 10px;
  display: flex;
  flex-direction: row-reverse;
}
.deleteIcon{
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.deleteItem {
  margin: 0 0 10px 10px;
  display: flex;
  flex-direction: row-reverse;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
::v-deep .el-button.is-disabled:hover{
  background: #143168;
  border-color: #DCDFE6;
}
</style>
